import Slider from "react-infinite-logo-slider";

import ANSI from "../../assets/images/ansi.webp";
import APPLUS from "../../assets/images/applus.webp";
import CARDER from "../../assets/images/Carder.webp";
import IAF from "../../assets/images/IAF.webp";
import IEC from "../../assets/images/IEC.webp";
import RETIE from "../../assets/images/certecnica.webp";
import ASMT from '../../assets/images/ASTM_logo.png';
import Lenor from '../../assets/images/lenor.png';
import { ReactComponent as Onac } from "../../assets/images/onac.svg";

import "./certificados.styles.scss";

const Certificados = () => {
	const data = [
		{ svg: false, src: ANSI, alt: "ANSI" },
		{ svg: false, src: APPLUS, alt: "APPLUS" },
		{ svg: false, src: CARDER, alt: "CARDER" },
		{ svg: false, src: IAF, alt: "IAF" },
		{ svg: false, src: IEC, alt: "IEC" },
		{ svg: false, src: RETIE, alt: "RETIE" },
		{ svg: true, src: <Onac /> },
		{ svg: false, src: ASMT, alt: "ASMT" },
		{ svg: false, src: Lenor, alt: "Lenor" },
	];

	return (
		<div className="certificados-container">
			<div className="certificados-container__bg-yellow"></div>
			<div className="certificados-container__bg-white">
				<h2>Certificaciones</h2>
				<div className="certificados-container__certificaciones">
					<Slider
						width="200px"
						duration={20}
						pauseOnHover={true}
						blurBorders={false}
						blurBoderColor={"#fff"}
					>
						{data.map((item, index) =>
							!item.svg ? (
								<Slider.Slide key={index} className="certificado">
									<img src={item.src} alt={item.alt} />
								</Slider.Slide>
							) : (
								<Slider.Slide key={index}>
									<Onac />
								</Slider.Slide>
							)
						)}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Certificados;
