import { useState, useEffect } from "react";

import { getImage } from "../../utils/image-provider";
import circularData from "../../utils/circula.data.json";
import SlideIndicator from "../slide-indicator/slide-indicator.component";

import "./servicios-section.styles.scss";

const ServiciosSection = () => {
	const [currentTitle, setCurrentTitle] = useState(circularData[0].title);
	const [currentPosition, setCurrentPosition] = useState(0);
	const totalItems = circularData.length;

	const handleScroll = (event) => {
		const position = Math.ceil(event.target.scrollTop / 264);
		position < totalItems && setCurrentPosition(position);
	};

	useEffect(() => {
		setCurrentTitle(circularData[currentPosition].title);
	}, [currentPosition]);

	return (
		<section className="economia-circular_container">
			<div className="content_container">
				<div className="header_content">
					<div className="header_content-text">
						<div className="title_container">
							<div className="title">
								<span>Economía Circular</span>
							</div>
							<div className="title_icon">{getImage("RayoIcon", 70, "")}</div>
						</div>
						<div className="sub_title">
							<p className="sub_title-text">{currentTitle}</p>
							<SlideIndicator
								currentPosition={currentPosition}
								totalItems={totalItems}
							/>
						</div>
					</div>
					<div className="header_content-icon"></div>
				</div>
				<div onScroll={handleScroll} className="content_box">
					{circularData.map(({ id, image, image_alt, content }) => (
						<div id={`marker_${id}`} className="content_item" key={id}>
							<div className="img_content">
								{getImage(image, 200, image_alt)}
							</div>
							<div className="text_content">{content}</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default ServiciosSection;
