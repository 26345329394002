import { Link } from "react-router-dom";

import Divider from "../divider/divider.component";
import Certificados from "../certificados/certificados.component";
import { FaWhatsapp } from "react-icons/fa";

import "./footer.styles.scss";

const FooterComponent = () => {
	const date = new Date();
	const year = date.getFullYear();
	return (
		<div className="footer-container">
			<Certificados />
			<div className="foter-sections">
				<div className="footer-section">
					<div className="footer-links">
						<a
							href="https://wa.me/573166879902"
							rel="noreferrer"
							target="_blank"
							className="footer-whatsapp"
						>
							<span className="whatsapp-container-footer">
								<FaWhatsapp />
							</span>
						</a>
						<Link to="/" className="link-item" role="menuitem">
							Inicio
						</Link>
						<Link to="nosotros" className="link-item" role="menuitem">
							Nosotros
						</Link>
						<Link to="economia-circular" className="link-item" role="menuitem">
							Economía Circular
						</Link>
						<Link to="homologacion" className="link-item" role="menuitem">
							Homologación
						</Link>
						<Link to="productos" className="link-item" role="menuitem">
							Productos
						</Link>
					</div>
				</div>
				<div className="footer-section">
					<div className="footer-links">
						<p>
							Carrera 22 # 63B - 41 <br /> Bogotá, D.C., Colombia
						</p>
						<p>
							Llamanos:
							<br />
							<br />
							<a href="tel:+57-316-687-9902" target="_blank" rel="noreferrer">
								+57 316 687 9902
							</a>
							<br />
							<a href="tel:+57-311-440-8260" target="_blank" rel="noreferrer">
								+57 311 440 8260
							</a>
							<br />
							<a href="tel:+57-311-257-7121" target="_blank" rel="noreferrer">
								+57 311 257 7121
							</a>
						</p>
					</div>
				</div>
				<div className="footer-section">
					<div className="footer-links">
						Escr&iacute;benos:
						<br />
						<a
							href="mailto:contacto@epicables.com"
							target="_blank"
							rel="noreferrer"
						>
							contacto@epicables.com
						</a>
						Ventas:<br />
						<a
							href="mailto:ventas1@epicables.com"
							target="_blank"
							rel="noreferrer"
						>
							ventas1@epicables.com
						</a>
					</div>
				</div>
			</div>
			<Divider />
			<p className="legal-p">©Epic Cables {year}</p>
		</div>
	);
};

export default FooterComponent;
