import { getImage } from "../../utils/image-provider";
import IconTitleBar from "../../components/icon-title-bar/icon-title-bar.component";

import "./nosotros.styes.scss";

const NosotrosPage = () => {
	return (
		<main>
			<section className="nosotros">
				<div className="nosotros__titlecontainer">
					<IconTitleBar title="Nosotros" theme="dark" icon="RayoIconAmarillo" />
				</div>
				<div className="nosotros__content">
					<div className="nosotros__content__text">
						<p>
							Somos una empresa con más de 14 de años de experiencia
							especializada en cables eléctricos, en EPIC{" "}
							<b>
								colaboramos estrechamente con nuestros clientes para determinar
								soluciones integrales adaptadas a las necesidades específicas en
								cables eléctricos.
							</b>
							&nbsp;Así, a través del conocimiento detallado de la aplicación,
							condiciones de instalación, requerimientos normativos específicos,
							características técnicas y requisitos particulares; se proporciona
							una solución efectiva a la necesidad de cables asegurando el
							cumplimiento de las condiciones dadas.
						</p>
						<p>
							Del mismo modo,
							<b>
								trabajamos en estrecha colaboración con diversas fábricas
								aliadas
							</b>
							, tanto nacionales como internacionales, permitiéndonos ofrecer
							soluciones reales, de vanguardia y adaptadas a las necesidades
							específicas.
						</p>
						<p>
							Adicionalmente, conocemos el impacto del posconsumo de conductores
							eléctricos el cual se encuentra caracterizado por prácticas
							contaminantes, informalidad, actividades delictivas y
							consecuencias sociales adversas. En consecuencia y como parte de
							nuestra responsabilidad medioambiental{" "}
							<b>
								llevamos a cabo una gestión responsable, sostenible, ética y
								legal de los residuos de cables, implementando una estrategia
								basada en los principios de la economía circular.
							</b>
						</p>
					</div>
					<div className="nosotros__content__image">
						{getImage("nosotrosContent", "100%", "Epic Cables - Nosotros")}
					</div>
				</div>
			</section>
			<section className="ecosistema">
				<div className="ecosistema__titlecontainer">
					<IconTitleBar title="Nuestro Ecosistema de Servicios" theme="clear" />
				</div>
				<div className="ecosistema__content">
					<div className="ecosistema__content__item-container">
						<div className="ecosistema__content__item-container__item">
							<div className="item_header">
								<div className="numero">&nbsp;1&nbsp;</div>
								<div className="title">Consultoría y homologación</div>
							</div>
							<div className="content">
								Orientación especializada en la selección de conductores
								eléctricos, respaldada por más de 15 años de experiencia en
								fabricación y diseño.
							</div>
						</div>
						<div className="ecosistema__content__item-container__item">
							<div className="item_header">
								<div className="numero">&nbsp;2&nbsp;</div>
								<div className="title">Soluciones personalizadas</div>
							</div>
							<div className="content">
								Adaptamos nuestras soluciones para resolver desafíos en tiempos,
								flujo de caja, certificaciones y condiciones técnicas y
								comerciales.
							</div>
						</div>
					</div>
					<div className="ecosistema__content__item-container">
						<div className="ecosistema__content__item-container__item">
							<div className="item_header">
								<div className="numero">&nbsp;3&nbsp;</div>
								<div className="title">Suministro Estratégico</div>
							</div>
							<div className="content">
								Gracias a nuestras fuertes alianzas con fábricas nacionales e
								internacionales contamos con un portafolio de cables críticos en
								proyectos de ingeniería eléctrica.
							</div>
						</div>
						<div className="ecosistema__content__item-container__item">
							<div className="item_header">
								<div className="numero">&nbsp;4&nbsp;</div>
								<div className="title">Logística Eficiente</div>
							</div>
							<div className="content">
								Entregas nacionales e internacionales según las necesidades.
								Facilita compras y entregas adaptadas a cada cliente.
							</div>
						</div>
					</div>
					<div className="ecosistema__content__item-container">
						<div className="ecosistema__content__item-container__item">
							<div className="item_header">
								<div className="numero">&nbsp;5&nbsp;</div>
								<div className="title">Servicio Técnico Postventa</div>
							</div>
							<div className="content">
								Equipo técnico especializado en normativas nacionales e
								internacionales atiende requerimientos postventa responsable,
								oportuna y técnicamente.
							</div>
						</div>
						<div className="ecosistema__content__item-container__item">
							<div className="item_header">
								<div className="numero">&nbsp;6&nbsp;</div>
								<div className="title">
									Circularidad de los cables eléctricos y gestión ambiental
								</div>
							</div>
							<div className="content">
								Gestionamos cables desechados para reciclarlos y obtener sus
								materias primas, fomentando la economía circular y cumpliendo
								con las normativas ambientales.
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="mision">
				<div className="mision__container">
					<div className="mision__container__title">MISIÓN</div>
					<div className="mision__container__text">
						Generar desarrollo social integral a largo plazo de nuestro entorno,
						siendo gestores de crecimiento de las fabricantes aliadas y creando
						ventajas competitivas de alto impacto para nuestros clientes.
					</div>
				</div>
			</section>
			<section className="valores">
				<div className="valores__titleContainer">
					<IconTitleBar title="Nuestros Valores" theme="clear" />
				</div>
				<div className="valores__content-container">
					<div className="valores__content-container__content">
						<div className="valores__content-container__content__items-container">
							<div className="valores__content-container__content__items-container__item bg-yellow">
								<div className="valores__content-container__content__items-container__item__title">
									Respeto
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									Creemos en el respeto mutuo en toda interacción, sin importar
									el rango, la posición, el tamaño, el género, la raza, la
									orientación sexual, la cultura y/o la religión.
								</div>
							</div>
							<div className="valores__content-container__content__items-container__item bg-yellow">
								<div className="valores__content-container__content__items-container__item__title">
									Ética
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									La ética guía nuestras acciones, decisiones y alianzas.
								</div>
							</div>
						</div>
						<div className="valores__content-container__content__items-container">
							<div className="valores__content-container__content__items-container__item bg-black">
								<div className="valores__content-container__content__items-container__item__title">
									Relaciones Justas
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									Todas las relaciones serán justas y honestas con los
									proveedores, los clientes, los trabajadores, la sociedad y los
									accionistas.
								</div>
							</div>
							<div className="valores__content-container__content__items-container__item bg-black">
								<div className="valores__content-container__content__items-container__item__title">
									Excelencia
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									Buscamos la excelencia en todo lo que hacemos.
								</div>
							</div>
						</div>
						<div className="valores__content-container__content__items-container">
							<div className="valores__content-container__content__items-container__item bg-yellow">
								<div className="valores__content-container__content__items-container__item__title">
									Todos importamos
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									Nos interesa conocer las necesidades de nuestros clientes,
									proveedores, trabajadores, sociedad y accionistas. Todos los
									actores de nuestro entorno importan y son relevantes.
								</div>
							</div>
							<div className="valores__content-container__content__items-container__item bg-yellow">
								<div className="valores__content-container__content__items-container__item__title">
									Conocimiento y capacitación
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									El conocimiento y capacitación hacen parte fundamental de
									nuestras interacciones y crecimiento.
								</div>
							</div>
						</div>
						<div className="valores__content-container__content__items-container">
							<div className="valores__content-container__content__items-container__item bg-black">
								<div className="valores__content-container__content__items-container__item__title">
									Responsables
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									Nos hacemos responsables de nuestros compromisos.
								</div>
							</div>
							<div className="valores__content-container__content__items-container__item bg-black">
								<div className="valores__content-container__content__items-container__item__title">
									Trabajo en equipo
								</div>
								<div className="valores__content-container__content__items-container__item__text">
									El trabajo en equipo y la toma de decisiones consensuadas es
									una prioridad.
								</div>
							</div>
						</div>
						<div className="valores__content-container__content__items-container__item bg-yellow">
							<div className="valores__content-container__content__items-container__item__title">
								Cuidado del medio ambiente
							</div>
							<div className="valores__content-container__content__items-container__item__text">
								Estamos comprometidos con el cuidado medio ambiente y fomentamos
								las buenas prácticas ambientales.
							</div>
						</div>
					</div>
				</div>
			</section>
			<div>{getImage("TeamImage", "100%", "Epic Cables - Nosotros")}</div>
		</main>
	);
};

export default NosotrosPage;
