import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

import Hero from '../../components/hero/hero.component';
import FooterComponent from "../../components/footer/footer.component";

import EpicLogoAmarillo from "../../assets/images/logo_amarillo.webp";
import { ReactComponent as DownArrow } from "../../assets/images/down-arrow.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { FaWhatsapp } from "react-icons/fa";

import "./navigate.styles.scss";

const Navigate = () => {
	const [isMobileOpen, setIsMobileOpen] = useState(false);

	const handleMenu = () => {
		setIsMobileOpen(!isMobileOpen);
	};
	return (
		<>
			<nav className="navigation-container" role="menu">
				<div className="logo_container">
					<Link to="/" role="menuitem">
						<div className="page-logo">
							<img src={EpicLogoAmarillo} alt="Epic Cables" />
						</div>
					</Link>
				</div>
				<div className="links-container">
					<Link to="/" className="link-item" role="menuitem">
						Inicio
					</Link>
					<Link to="nosotros" className="link-item" role="menuitem">
						Nosotros
					</Link>
					<Link to="economia-circular" className="link-item" role="menuitem">
						Economía Circular
					</Link>
					<Link to="homologacion" className="link-item" role="menuitem">
						Homologación
					</Link>
					<Link to="productos" className="link-item" role="menuitem">
						Productos
					</Link>
					<a href="https://wa.me/573166879902" rel="noreferrer" target="_blank">
						<div className="header-whatsapp-container">
							<FaWhatsapp />
						</div>
					</a>
				</div>
				<div
					className={`mobile-links-container ${
						!isMobileOpen && "hide_element"
					}`}
				>
					<Link to="/" className="link-item" role="menuitem">
						Inicio
					</Link>
					<Link to="nosotros" className="link-item" role="menuitem">
						Nosotros
					</Link>
					<Link to="economia-circular" className="link-item" role="menuitem">
						Economía Circular
					</Link>
					<Link to="homologacion" className="link-item" role="menuitem">
						Homologación
					</Link>
					<Link to="productos" className="link-item" role="menuitem">
						Productos
					</Link>
					<a href="https://wa.me/573166879902" rel="noreferrer" target="_blank">
						<div className="header-whatsapp-container">
							<FaWhatsapp />
						</div>
					</a>
				</div>
				<div
					className={`${isMobileOpen && "hide_element"} arrow_menu toggle_menu`}
					onClick={handleMenu}
				>
					<DownArrow />
				</div>
				<div
					className={`${
						!isMobileOpen && "hide_element"
					} close_menu toggle_menu`}
					onClick={handleMenu}
				>
					<CloseIcon />
				</div>
			</nav>
			<Hero />
			<Outlet />
			<FooterComponent />
		</>
	);
};

export default Navigate;
