import { CATEGORIAS } from "./products.data";

import SOLAR from "../assets/images/energia-solar.png";
import EOLICA from "../assets/images/energia-eolica.png";
import HIDRO_Y_TERMICA from "../assets/images/hidroel-2.png";
import SUBESTACIONES from "../assets/images/subestaciones.png";
import TIERRA from "../assets/images/puerta-tierra.png";
import OIL_GAS from "../assets/images/oil-y-gas.png";
import MOVILES from "../assets/images/moviles-asce-2.png";
import TRANSMISION from "../assets/images/trasnmi.png";
import DISTRIBUCION from "../assets/images/distribu.png";
import CONSTRUCCION from "../assets/images/contrucc.png";
import DOMOTICA from "../assets/images/automa-y-domoti.png";
import MINERIA from "../assets/images/mineria.png";
import SUMERGIBLE from "../assets/images/sumergibles.png";
import TABLEROS from "../assets/images/tableros.png";
import TUNELES from "../assets/images/tuneles.png";
import FERROVIAS from "../assets/images/ferrovias.png";
import PORTUARIO from "../assets/images/Puertos-gruas-y-asc.png";
import HEROHOME from "../assets/images/hero_home.webp";

import ID1 from "../assets/images/01_CCA_Alambre.webp";
import ID2 from "../assets/images/02_CCD_Cable.webp";
import ID3 from "../assets/images/03_CCDE-Cable.webp";
import ID4 from "../assets/images/04_ACDE_Alambre.webp";
import ID5 from "../assets/images/05_ACDE_Alambre.webp";
import ID6 from "../assets/images/06_AAC_Cable.webp";
import ID7 from "../assets/images/07_-ACSR_7H_Cable.webp";
import ID8 from "../assets/images/08_ACSR_26-7H_Cable.webp";
import ID9 from "../assets/images/09_ACSR_18-1H_Cable.webp";
import ID10 from "../assets/images/10_AAAC_Cable.webp";
import ID11 from "../assets/images/11-acar-cable.webp";
import ID12 from "../assets/images/12-ccs-cable.webp";
import ID13 from "../assets/images/13-ccs-alambre.webp";
import ID14 from "../assets/images/14-acs-aluclad-cable.webp";
import ID15 from "../assets/images/15-cable-galvanizado.webp";
import ID16 from "../assets/images/16-thhn-2-cable.webp";
import ID17 from "../assets/images/17-thnw-2-cable.webp";
import ID18 from "../assets/images/18-xhhw-2-cable.webp";
import ID19 from "../assets/images/19-use-2-cable.webp";
import ID20 from "../assets/images/20_RHW-2_Cable.webp";
import ID21 from "../assets/images/21_Semiaislado_AAAC_Cable.webp";
import ID22 from "../assets/images/22_Semiasilado_ACSR_Cable.webp";
import ID23 from "../assets/images/23_Media_Tension_Apantallado_Cinta_Cable.webp";
import ID24 from "../assets/images/24_Media_Tension_Apantallado_Cinta_Hilos_Cable.webp";
import ID25 from "../assets/images/25_Media_Tension_Apantallado_Hilos_Cable.webp";
import ID26 from "../assets/images/26_MediaTenAlumApantaCint.webp";
import ID27 from "../assets/images/27_MediaTensiAlumunApantaHilos.webp";
import ID28 from "../assets/images/28_MediTensiAlumiAptantaCintaHilos.webp";
import ID29 from "../assets/images/29_Cable_TOXFREE.webp";
import ID30 from "../assets/images/30_TFF_GPT_Soldador_Cable.webp";
import ID31 from "../assets/images/31_Antifraude_Cable.webp";
import ID32 from "../assets/images/32_TREBOL_Cable.webp";
import ID33 from "../assets/images/33_DUPLEX_Cable.webp";
import ID34 from "../assets/images/34_TRIPLEX_Cable.webp";
import ID35 from "../assets/images/35_CUADRUPLEX_Cable.webp";
import ID36 from "../assets/images/36_PV_2kV_Cu-CuTn_Al_2kV_Cable.webp";
import ID37 from "../assets/images/37_HZZ122-KKV18_Cable.webp";
import ID38 from "../assets/images/38_PV1-F1.8kVcc_Cable.webp";
import ID39 from "../assets/images/39_NM_Cable.webp";
import ID40 from "../assets/images/40_Fuerza_Flexible-Encauchetado_SJT_SJW_ST_Cable.webp";
import ID41 from "../assets/images/41_VVk_Cable.png.webp";
import ID42 from "../assets/images/42_RV-K_Cable.webp";
import ID43 from "../assets/images/43_ROV-K_Cable.webp";
import ID44 from "../assets/images/44_ROZ1FZ1_Cable_11zon.webp";
import ID45 from "../assets/images/45_ROZ1MZ1_Cable.webp";
import ID46 from "../assets/images/46_VOV-K_Cable.webp";
import ID47 from "../assets/images/47_VOVMV_Cable.webp";
import ID48 from "../assets/images/48_Cable_VVFV.webp";
import ID49 from "../assets/images/49_Z1HOZ1_cable.webp";
import ID50 from "../assets/images/50_RC4Z1_Cable.webp";
import ID51 from "../assets/images/51_Z1C4Z1_Cable.webp";
import ID53 from "../assets/images/53_VC4V_Cable.webp";
import ID54 from "../assets/images/54_YSLCY_Cable.webp";
import ID55 from "../assets/images/55_RVC4V_Cable.webp";
import ID56 from "../assets/images/56_NYCWY_Cable.webp";
import ID57 from "../assets/images/57_RVC7V_Cable.webp";
import ID58 from "../assets/images/58_2YSL_Cable.webp";
import ID59 from "../assets/images/59_Pantalla_General_Cable.webp";
import ID60 from "../assets/images/60_Pantalla_General_Individual_Cable.webp";
import ID61 from "../assets/images/61_Pantalla_en_Trenzas_de_Cobre_Estañado_Cable.webp";
import ID62 from "../assets/images/62_ESY_mm2_Cable.webp";
import ID63 from "../assets/images/63_ARMADOS_Cable.webp";
import ID64 from "../assets/images/64_Cable_El-KeyPad-BOTONERA_2F-BP.webp";
import ID65 from "../assets/images/65_El-Reeling_PUR-HF_0-6-1KV_BP_Cable.webp";
import ID66 from "../assets/images/66_El-Reeling_NSHTOU-0-6-1KV_BP_Cable.webp";
import ID67 from "../assets/images/67_Cable_Plus_(N)SHTOU_(SMK)-BP.webp";
import ID68 from "../assets/images/68_El-Flat_H07VVH6-F_450-750V_BP_Cable.webp";
import ID69 from "../assets/images/69_El-Flat-CY_KYCFLY_300-500V_BP_Cable.webp";
import ID70 from "../assets/images/70_El-Flat_NGFLGOU_0-6_1KV-BPCable.webp";
import ID71 from "../assets/images/71_El-MVSt_(N)TMCGEWOU_3-6_6KV-18_30KV-BP_Cable.webp";
import ID72 from "../assets/images/72_El-MVMines_(N)TSCGEWOU_3-6_6KV-12_20KV-BP_Cable.webp";
import ID73 from "../assets/images/73_Cable_El-MVTunnel_(N)3GHSSHCH_3-6-6KV_12-20_KV.webp";

import DiagramaCircular from "../assets/images/diagrama.svg";
import RayoIcon from "../assets/images/Rayo Iconos epic.svg";
import RayoIconAmarillo from "../assets/images/Rayo amarillo Iconos epic.svg"
import EconomiaCircular from "../assets/images/economia_circular.webp";
import EconomiaCircularBig from "../assets/images/economia_circular_big.webp";
import Consultoria_Homologacion from "../assets/images/Consultoria_Homologacion.webp";
import Ajuste_Restricciones from "../assets/images/Ajuste_Restricciones.png";
import Suministro_Estrategico from "../assets/images/Suministro_Estrategico.png";
import Logistica_Eficiente from "../assets/images/Logistica_Eficiente.webp";
import Servicio_Tecnico_Postventa from "../assets/images/Servicio_Tecnico_Postventa.webp";
import Recompra_Gestion_Ambiental from "../assets/images/Recompra_Gestion_Ambiental.png";
import Reinsercion_Residuos_Produccion from "../assets/images/Reinsercion_Residuos_Produccion.png";
import CircularRecompensa from "../assets/images/circular_recompensa.webp";
import Procesos from "../assets/images/procesos.webp";
import TeamImage from '../assets/images/team_banner.webp'; 
import SubestacionesDark from "../assets/images/epic_Subestaciones_dark.svg";
import heroTrabajo from '../assets/images/hero_trabajo.webp';
import productosDescripcion from '../assets/images/productosDescripcion.webp';
import nosotrosBanner from '../assets/images/nosotros_banner.webp';
import nosotrosContent from '../assets/images/nosotros_content.webp';

import Hero2 from '../assets/images/hero2.webp';
import Hero3 from "../assets/images/hero3.webp";
import Hero4 from "../assets/images/hero4.webp";
import Hero5 from "../assets/images/hero5.webp";
import Hero6 from "../assets/images/hero6.webp";
import Hero7 from "../assets/images/hero7.webp";

import Transelca from '../assets/images/transelca.png';
import Ecopetrol from '../assets/images/ecopetrol.png';
import Celsia from '../assets/images/celcia.png';

export const getImage = (image, width, alt, lazy = true) => {
	let source = "";
	switch (image) {
		case CATEGORIAS.HOME:
			source = HEROHOME;
			break;
		case CATEGORIAS.CONSTRUCCION:
			source = CONSTRUCCION;
			break;
		case CATEGORIAS.DISTRIBUCION:
			source = DISTRIBUCION;
			break;
		case CATEGORIAS.DOMOTICA:
			source = DOMOTICA;
			break;
		case CATEGORIAS.EOLICA:
			source = EOLICA;
			break;
		case CATEGORIAS.FERROVIAS:
			source = FERROVIAS;
			break;
		case CATEGORIAS.HIDRO_Y_TERMICA:
			source = HIDRO_Y_TERMICA;
			break;
		case CATEGORIAS.MINERIA:
			source = MINERIA;
			break;
		case CATEGORIAS.MOVILES:
			source = MOVILES;
			break;
		case CATEGORIAS.OIL_GAS:
			source = OIL_GAS;
			break;
		case CATEGORIAS.PORTUARIO:
			source = PORTUARIO;
			break;
		case CATEGORIAS.SOLAR:
			source = SOLAR;
			break;
		case CATEGORIAS.SUBESTACIONES:
			source = SUBESTACIONES;
			break;
		case CATEGORIAS.SUMERGIBLE:
			source = SUMERGIBLE;
			break;
		case CATEGORIAS.TABLEROS:
			source = TABLEROS;
			break;
		case CATEGORIAS.TIERRA:
			source = TIERRA;
			break;
		case CATEGORIAS.TRANSMISION:
			source = TRANSMISION;
			break;
		case CATEGORIAS.TUNELES:
			source = TUNELES;
			break;
		case 1:
			source = ID1;
			break;
		case 2:
			source = ID2;
			break;
		case 3:
			source = ID3;
			break;
		case 4:
			source = ID4;
			break;
		case 5:
			source = ID5;
			break;
		case 6:
			source = ID6;
			break;
		case 7:
			source = ID7;
			break;
		case 8:
			source = ID8;
			break;
		case 9:
			source = ID9;
			break;
		case 10:
			source = ID10;
			break;
		case 11:
			source = ID11;
			break;
		case 12:
			source = ID12;
			break;
		case 13:
			source = ID13;
			break;
		case 14:
			source = ID14;
			break;
		case 15:
			source = ID15;
			break;
		case 16:
			source = ID16;
			break;
		case 17:
			source = ID17;
			break;
		case 18:
			source = ID18;
			break;
		case 19:
			source = ID19;
			break;
		case 20:
			source = ID20;
			break;
		case 21:
			source = ID21;
			break;
		case 22:
			source = ID22;
			break;
		case 23:
			source = ID23;
			break;
		case 24:
			source = ID24;
			break;
		case 25:
			source = ID25;
			break;
		case 26:
			source = ID26;
			break;
		case 27:
			source = ID27;
			break;
		case 28:
			source = ID28;
			break;
		case 29:
			source = ID29;
			break;
		case 30:
			source = ID30;
			break;
		case 31:
			source = ID31;
			break;
		case 32:
			source = ID32;
			break;
		case 33:
			source = ID33;
			break;
		case 34:
			source = ID34;
			break;
		case 35:
			source = ID35;
			break;
		case 36:
			source = ID36;
			break;
		case 37:
			source = ID37;
			break;
		case 38:
			source = ID38;
			break;
		case 39:
			source = ID39;
			break;
		case 40:
			source = ID40;
			break;
		case 41:
			source = ID41;
			break;
		case 42:
			source = ID42;
			break;
		case 43:
			source = ID43;
			break;
		case 44:
			source = ID44;
			break;
		case 45:
			source = ID45;
			break;
		case 46:
			source = ID46;
			break;
		case 47:
			source = ID47;
			break;
		case 48:
			source = ID48;
			break;
		case 49:
			source = ID49;
			break;
		case 50:
			source = ID50;
			break;
		case 51:
			source = ID51;
			break;
		case 53:
			source = ID53;
			break;
		case 54:
			source = ID54;
			break;
		case 55:
			source = ID55;
			break;
		case 56:
			source = ID56;
			break;
		case 57:
			source = ID57;
			break;
		case 58:
			source = ID58;
			break;
		case 59:
			source = ID59;
			break;
		case 60:
			source = ID60;
			break;
		case 61:
			source = ID61;
			break;
		case 62:
			source = ID62;
			break;
		case 63:
			source = ID63;
			break;
		case 64:
			source = ID64;
			break;
		case 65:
			source = ID65;
			break;
		case 66:
			source = ID66;
			break;
		case 67:
			source = ID67;
			break;
		case 68:
			source = ID68;
			break;
		case 69:
			source = ID69;
			break;
		case 70:
			source = ID70;
			break;
		case 71:
			source = ID71;
			break;
		case 72:
			source = ID72;
			break;
		case 73:
			source = ID73;
			break;
		case "DiagramaCircular":
			source = DiagramaCircular;
			break;
		case "RayoIcon":
			source = RayoIcon;
			break;
		case "RayoIconAmarillo":
			source = RayoIconAmarillo;
			break;
		case "EconomiaCircular":
			source = EconomiaCircular;
			break;
		case "EconomiaCircularBig":
			source = EconomiaCircularBig;
			break;
		case "Consultoria_Homologacion":
			source = Consultoria_Homologacion;
			break;
		case "Ajuste_Restricciones":
			source = Ajuste_Restricciones;
			break;
		case "Suministro_Estrategico":
			source = Suministro_Estrategico;
			break;
		case "Logistica_Eficiente":
			source = Logistica_Eficiente;
			break;
		case "Servicio_Tecnico_Postventa":
			source = Servicio_Tecnico_Postventa;
			break;
		case "Recompra_Gestion_Ambiental":
			source = Recompra_Gestion_Ambiental;
			break;
		case "Reinsercion_Residuos_Produccion":
			source = Reinsercion_Residuos_Produccion;
			break;
		case "Circular_Recompensa":
			source = CircularRecompensa;
			break;
		case "Procesos":
			source = Procesos;
			break;
		case "TeamImage":
			source = TeamImage;
			break;
		case "SubestacionesDark":
			source = SubestacionesDark;
			break;
		case "heroTrabajo":
			source = heroTrabajo;
			break;
		case "productosDescripcion":
			source = productosDescripcion;
			break;
		case "nosotrosBanner":
			source = nosotrosBanner;
			break;
		case "nosotrosContent":
			source = nosotrosContent;
			break;
		case "Hero2":
			source = Hero2;
			break;
		case "Hero3":
			source = Hero3;
			break;
		case "Hero4":
			source = Hero4;
			break;
		case "Hero5":
			source = Hero5;
			break;
		case "Hero6":
			source = Hero6;
			break;
		case "Hero7":
			source = Hero7;
			break;
		case "transelca":
			source = Transelca;
			break;
		case "ecopetrol":
			source = Ecopetrol;
			break;
		case "celsia":
			source = Celsia;
			break;
		default:
			console.warn("Image not found");
	}

	return (
		<img
			src={source}
			alt={alt}
			width={width}
			height="auto"
			title={alt}
			loading={lazy ? "lazy" : "eager"}
		/>
	);
};
