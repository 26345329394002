export const CATEGORIAS = {
	CONSTRUCCION: "Construcción",
	DISTRIBUCION: "Distribución",
	DOMOTICA: "Automatización y domótica",
	EOLICA: "Energía eólica",
	FERROVIAS: "Ferrovías y metro",
	HIDRO_Y_TERMICA: "Hidroeléctrica y energía térmica",
	MINERIA: "Minería",
	MOVILES: "Móviles (Vehículos / Elevadores)",
	OIL_GAS: "Oil & gas",
	PORTUARIO: "Portuario",
	SOLAR: "Energía solar",
	SUBESTACIONES: "Subestaciones",
	SUMERGIBLE: "Sumergible",
	TABLEROS: "Tableros",
	TIERRA: "Puestas a tierra",
	TRANSMISION: "Transmisión",
	TUNELES: "Tuneles",
};

export const PRODUCTS = [
	{
		id: 1,
		nombre_comercial: "CCA",
		hover: "Alambre de aluminio recubierto de cobre",
		imagen: "01_CCA_Alambre.webp",
		ref: "CCA Alambre",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.TIERRA,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.TABLEROS,
		],
	},
	{
		id: 2,
		nombre_comercial: "CCD",
		hover: "Cable de cobre desnudo",
		imagen: "02_CCD_Cable.webp",
		ref: "CCD Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TIERRA,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 3,
		nombre_comercial: "Estaño",
		hover: "Cable de cobre recubierto de estaño",
		imagen: "03_CCDE-Cable.webp",
		ref: "CCDE Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 4,
		nombre_comercial: "Estañado",
		hover: "Alambre de cobre recubierto de estaño",
		imagen: "04_ACDE_Alambre",
		ref: "ACDE Alambre",
		categorias: [CATEGORIAS.HIDRO_Y_TERMICA, CATEGORIAS.DISTRIBUCION],
	},
	{
		id: 5,
		nombre_comercial: "AAC",
		hover: "Alambre de aluminio / Para rayos / Alambron aluminio",
		imagen: "05_ACDE_Alambre.webp",
		ref: "ACDE Alambre",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
		],
	},
	{
		id: 6,
		nombre_comercial: "AAC",
		hover: "Cable de aluminio / Para rayos / Alambron aluminio",
		imagen: "06_AAC_Cable.webp",
		ref: "AAC Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TIERRA,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
		],
	},
	{
		id: 7,
		nombre_comercial: "ACSR",
		hover: "Cable de aluminio reforzado con acero / AWA / AWAC",
		imagen: "07_-ACSR_7H_Cable.webp",
		ref: "ACSR 7H Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
		],
	},
	{
		id: 8,
		nombre_comercial: "ACSR",
		hover: "Cable de aluminio reforzado con acero / AWA / AWAC",
		imagen: "08_ACSR_26-7H_Cable.webp",
		ref: "ACSR 26 7H Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
		],
	},
	{
		id: 9,
		nombre_comercial: "ACSR",
		hover: "Cable de aluminio reforzado con acero / AWA / AWAC",
		imagen: "09_ACSR_18-1H_Cable.webp",
		ref: "ACSR 18 1H Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
		],
	},
	{
		id: 10,
		nombre_comercial: "AAAC",
		hover: "Cable de aleación de aluminio",
		imagen: "10_AAAC_Cable.webp",
		ref: " AAAC Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 11,
		nombre_comercial: "ACAR",
		hover: "Cable de aluminio reforzado con aleación de aluminio",
		imagen: "11-acar-cable.webp",
		ref: "acar cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 12,
		nombre_comercial: "CCS",
		hover: "Cable de acero recubierto de cobre (COOPERWELD)",
		imagen: "12-ccs-cable.webp",
		ref: "ccs cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TIERRA,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.CONSTRUCCION,
		],
	},
	{
		id: 13,
		nombre_comercial: "CCS",
		hover: "Alambre de acero recubierto de cobre (COOPERWELD)",
		imagen: "13-ccs-alambre.webp",
		ref: "ccs alambre",
		categorias: [CATEGORIAS.SUBESTACIONES, CATEGORIAS.TIERRA],
	},
	{
		id: 14,
		nombre_comercial: "ACS",
		hover: "Cable de acero recubierto de aluminio (ALUMOWELD / ALUCLAD)",
		imagen: "14-acs-aluclad-cable.webp",
		ref: "acs aluclad cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TIERRA,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
		],
	},
	{
		id: 15,
		nombre_comercial: "CCS",
		hover: "Cable de acero recubierto de zinc",
		imagen: "15-cable-galvanizado.webp",
		ref: "cable galvanizado",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TIERRA,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
		],
	},
	{
		id: 16,
		nombre_comercial: "THHN-2",
		hover: "THHN-2 serie 8000",
		imagen: "16-thhn-2-cable.webp",
		ref: "thhn-2 cable",
		categorias: [
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
		],
	},
	{
		id: 17,
		nombre_comercial: "THNW-2",
		hover: "THNW-2 serie 8000",
		imagen: "17-thnw-2-cable.webp",
		ref: "thnw-2 cable",
		categorias: [CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 18,
		nombre_comercial: "XHHW-2",
		hover: "Cable XHHW-2 serie 8000",
		imagen: "18-xhhw-2-cable.webp",
		ref: "xhhw-2 cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.TABLEROS,
		],
	},
	{
		id: 19,
		nombre_comercial: "USE-2",
		hover: "Cable USE-2 serie 8000",
		imagen: "19-use-2-cable.webp",
		ref: "use-2 cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
		],
	},
	{
		id: 20,
		nombre_comercial: "RHW-2",
		hover: "Cable RHW-2 serie 8000",
		imagen: "20_RHW-2_Cable.webp",
		ref: "RHW-2 Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
		],
	},
	{
		id: 21,
		nombre_comercial: "Ecológico ",
		hover: "Cable aéreo semiaislado / Ecológico / Treewire",
		imagen: "21_Semiaislado_AAAC_Cable.webp",
		ref: "Semiaislado AAAC Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.TRANSMISION,
			CATEGORIAS.DISTRIBUCION,
		],
	},
	{
		id: 22,
		nombre_comercial: "Ecológico ",
		hover: "Cable aéreo semiaislado / Ecológico / Treewire",
		imagen: "22_Semiasilado_ACSR_Cable.webp",
		ref: "Semiasilado ACSR Cable",
		categorias: [CATEGORIAS.TRANSMISION, CATEGORIAS.DISTRIBUCION],
	},
	{
		id: 23,
		nombre_comercial: "Media tensión",
		hover:
			"CABLE AISLADO MEDIA TENSION / CABLE MT COBRE / CABLE MT ALUMINIO / JUMPER",
		imagen: "23_Media_Tension_Apantallado_Cinta_Cable.webp",
		ref: "Media Tension Apantallado Cinta Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 24,
		nombre_comercial: "Media tensión",
		hover:
			"CABLE AISLADO MEDIA TENSION / CABLE MT COBRE / CABLE MT ALUMINIO / JUMPER",
		imagen: "24_Media_Tension_Apantallado_Cinta_Hilos_Cable.webp",
		ref: "Media Tension Apantallado Cinta Hilos Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 25,
		nombre_comercial: "Media tensión",
		hover:
			"CABLE AISLADO MEDIA TENSION / CABLE MT COBRE / CABLE MT ALUMINIO / JUMPER",
		imagen: "25_Media_Tension_Apantallado_Hilos_Cable.webp",
		ref: "Media Tension Apantallado Hilos Cable.webp",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 26,
		nombre_comercial: "Media tensión",
		hover:
			"CABLE AISLADO MEDIA TENSION / CABLE MT COBRE / CABLE MT ALUMINIO / JUMPER",
		imagen: "26_MediaTenAlumApantaCint.webp",
		ref: "Media Tension Aluminio Apantallado Cinta",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 27,
		nombre_comercial: "Media tensión",
		hover:
			"CABLE AISLADO MEDIA TENSION / CABLE MT COBRE / CABLE MT ALUMINIO / JUMPER",
		imagen: "27_MediaTensiAlumunApantaHilos.webp",
		ref: "Cable Media Tensión Aluminio Apantallado Hilos",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DISTRIBUCION,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 28,
		nombre_comercial: "Media tensión",
		hover:
			"CABLE AISLADO MEDIA TENSION / CABLE MT COBRE / CABLE MT ALUMINIO / JUMPER",
		imagen: "28_MediTensiAlumiAptantaCintaHilos.webp",
		ref: "Media Tensión Aluminio Apantallado Cinta + Hilos Cable",
		categorias: [
			CATEGORIAS.SOLAR,
			CATEGORIAS.EOLICA,
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MOVILES,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 29,
		nombre_comercial: "LIBRE HALOGENOS",
		hover: "HFLS / LSHF/  TOXFREE /AS+ / SERIE 8000",
		imagen: "29_Cable_TOXFREE.webp",
		ref: "TOXFREE cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 30,
		nombre_comercial: "MONIOPOLARES FLEXIBLES",
		hover:
			"VEHICULARES / SIS / MTW / AWM / ILUMINACION / TFF / SOLDADOR / BATERIA",
		imagen: "30_TFF_GPT_Soldador_Cable.webp",
		ref: "TFF, GPT, Soldador Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.TIERRA,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MOVILES,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 31,
		nombre_comercial: "ACOMETIDAS",
		hover: "ACOMETIDA CONCENTRICA, TREBOL, ANTIFRAUDE / SEU / SERIE 8000",
		imagen: "31_Antifraude_Cable.webp",
		ref: "Antifraude Concentrico Cable",
		categorias: [CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 32,
		nombre_comercial: "MULTIPLEX",
		hover: "DUPLEX / TRIPLEX / CUATRUPLEX",
		imagen: "32_TREBOL_Cable.webp",
		ref: "TREBOL Cable",
		categorias: [CATEGORIAS.DISTRIBUCION, CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 33,
		nombre_comercial: "MULTIPLEX",
		hover: "DUPLEX / TRIPLEX / CUATRUPLEX",
		imagen: "33_DUPLEX_Cable.webp",
		ref: "DUPLEX Cable",
		categorias: [CATEGORIAS.DISTRIBUCION, CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 34,
		nombre_comercial: "MULTIPLEX",
		hover: "DUPLEX / TRIPLEX / CUATRUPLEX",
		imagen: "34_TRIPLEX_Cable.webp",
		ref: "TRIPLEX Cable",
		categorias: [CATEGORIAS.DISTRIBUCION, CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 35,
		nombre_comercial: "MULTIPLEX",
		hover: "DUPLEX / TRIPLEX / CUATRUPLEX",
		imagen: "35_CUADRUPLEX_Cable.webp",
		ref: "CUADRUPLEX Cable",
		categorias: [CATEGORIAS.DISTRIBUCION, CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 36,
		nombre_comercial: "PV",
		hover: "FOTOVOLTAICO / SOLAR / PV",
		imagen: "36_PV_2kV_Cu-CuTn_Al_2kV_Cable.webp",
		ref: "PV 2kV Cu-CuTn-Al 2kV Cable",
		categorias: [CATEGORIAS.SOLAR],
	},
	{
		id: 37,
		nombre_comercial: "PV",
		hover: "FOTOVOLTAICO / SOLAR / PV",
		imagen: "37_HZZ122-KKV18_Cable.webp",
		ref: "HZZ122-KKV18 Cable",
		categorias: [CATEGORIAS.SOLAR],
	},
	{
		id: 38,
		nombre_comercial: "PV",
		hover: "FOTOVOLTAICO / SOLAR / PV",
		imagen: "38_PV1-F1.8kVcc_Cable.webp",
		ref: "PV1-F1.8kVcc Cable",
		categorias: [CATEGORIAS.SOLAR],
	},
	{
		id: 39,
		nombre_comercial: "NM",
		hover: "NM /NM-B",
		imagen: "39_NM_Cable.webp",
		ref: "NM Cable",
		categorias: [CATEGORIAS.CONSTRUCCION],
	},
	{
		id: 40,
		nombre_comercial: "CABLES DE FUERZA",
		hover:
			"CABLE FUERZA / POTENCIA /ENCAUCHETADO /MULTICONDUCTORES /CONTROL /LIBRES DE HALOGENOS /AS /AS+",
		imagen: "40_Fuerza_Flexible-Encauchetado_SJT_SJW_ST_Cable.webp",
		ref: "Fuerza Flexible-Encauchetado. SJT, SJW, ST Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 41,
		nombre_comercial: "CABLES DE FUERZA",
		hover:
			"CABLE FUERZA / POTENCIA /ENCAUCHETADO /MULTICONDUCTORES /CONTROL /LIBRES DE HALOGENOS /AS /AS+",
		imagen: "41_VVk_Cable.png.webp",
		ref: "VVk Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.TABLEROS,
		],
	},
	{
		id: 42,
		nombre_comercial: "CABLES DE FUERZA",
		hover:
			"CABLE FUERZA / POTENCIA /ENCAUCHETADO /MULTICONDUCTORES /CONTROL /LIBRES DE HALOGENOS /AS /AS+",
		imagen: "42_RV-K_Cable.webp",
		ref: "RV-K Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.TABLEROS,
		],
	},
	{
		id: 43,
		nombre_comercial: "CABLES APANTALLADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION /SERVO MOTOR / VDF / VARIADOR/ANTINCENDIO",
		imagen: "43_ROV-K_Cable.webp",
		ref: "ROV-K Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 44,
		nombre_comercial: "CABLES ARMADOS",
		hover: "ZONAS CLASIFICADAS / ATEX / AS / AS+ / MC",
		imagen: "44_ROZ1FZ1_Cable_11zon.webp",
		ref: "ROZ1FZ1 Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 45,
		nombre_comercial: "CABLES ARMADOS",
		hover: "ZONAS CLASIFICADAS / ATEX / AS / AS+ / MC",
		imagen: "45_ROZ1MZ1_Cable.webp",
		ref: "ROZ1MZ1 Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 46,
		nombre_comercial: "CABLES APANTALLADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION /SERVO MOTOR / VDF / VARIADOR/ANTINCENDIO",
		imagen: "46_VOV-K_Cable.webp",
		ref: "VOV-K Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 47,
		nombre_comercial: "CABLES ARMADOS",
		hover: "ZONAS CLASIFICADAS / ATEX / AS / AS+ / MC",
		imagen: "47_VOVMV_Cable.webp",
		ref: "VOVMV Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 48,
		nombre_comercial: "CABLES ARMADOS",
		hover: "ZONAS CLASIFICADAS / ATEX / AS / AS+ / MC",
		imagen: "48_Cable_VVFV.webp",
		ref: "Cable VVFV",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 49,
		nombre_comercial: "CABLES APANTALLADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION /SERVO MOTOR / VDF / VARIADOR/ANTINCENDIO",
		imagen: "49_Z1HOZ1_cable.webp",
		ref: "Z1HOZ1 cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.CONSTRUCCION,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 50,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "50_RC4Z1_Cable.webp",
		ref: "RC4Z1 Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 51,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "51_Z1C4Z1_Cable.webp",
		ref: "Z1C4Z1 Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 53,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "53_VC4V_Cable.webp",
		ref: "VC4V Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 54,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "54_YSLCY_Cable.webp",
		ref: "YSLCY Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 55,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "55_RVC4V_Cable.webp",
		ref: "RVC4V Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 56,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "56_NYCWY_Cable.webp",
		ref: "NYCWY Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 57,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "57_RVC7V_Cable.webp",
		ref: "RVC7V Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 58,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "58_2YSL_Cable.webp",
		ref: "2YSL Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 59,
		nombre_comercial: "CABLES APANTALLADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION /SERVO MOTOR / VDF / VARIADOR/ANTINCENDIO",
		imagen: "59_Pantalla_General_Cable.webp",
		ref: "Pantalla General Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 60,
		nombre_comercial: "CABLES APANTALLADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION /SERVO MOTOR / VDF / VARIADOR/ANTINCENDIO",
		imagen: "60_Pantalla_General_Individual_Cable.webp",
		ref: "Pantalla General Individual Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
		],
	},
	{
		id: 61,
		nombre_comercial: "CABLES BLINDADOS",
		hover:
			"CONTROL/INSTRUMENTACION/AUTOMATIZACION / APANTALLADOS/BLINDADOS/ 2ohm-km /SUB ESTACION",
		imagen: "61_Pantalla_en_Trenzas_de_Cobre_Estañado_Cable.webp",
		ref: "Pantalla en Trenzas de Cobre Estañado Cable",
		categorias: [
			CATEGORIAS.HIDRO_Y_TERMICA,
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.DOMOTICA,
			CATEGORIAS.TABLEROS,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 62,
		nombre_comercial: "MONIOPOLARES FLEXIBLES",
		hover:
			"VEHICULARES / SIS / MTW / AWM / ILUMINACION / TFF / SOLDADOR / BATERIA",
		imagen: "62_ESY_mm2_Cable.webp",
		ref: "ESY mm2 Cable",
		categorias: [CATEGORIAS.TIERRA],
	},
	{
		id: 63,
		nombre_comercial: "CABLES ARMADOS",
		hover: "ZONAS CLASIFICADAS / ATEX / AS / AS+ / MC",
		imagen: "63_ARMADOS_Cable.webp",
		ref: "ARMADOS Cable",
		categorias: [
			CATEGORIAS.SUBESTACIONES,
			CATEGORIAS.OIL_GAS,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 64,
		nombre_comercial: "APLICACIÓNES MOVILES",
		hover:
			"CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS /SHD-GC /G-GC",
		imagen: "64_Cable_El-KeyPad-BOTONERA_2F-BP.webp",
		ref: "El-KeyPad-BOTONERA 2F-BP cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.TUNELES,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 65,
		nombre_comercial: "PROTECCION MECANICA",
		hover: "CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS",
		imagen: "65_El-Reeling_PUR-HF_0-6-1KV_BP_Cable.webp",
		ref: "El-Reeling PUR-HF 0.6-1KV-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 66,
		nombre_comercial: "PROTECCION MECANICA",
		hover: "CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS",
		imagen: "66_El-Reeling_NSHTOU-0-6-1KV_BP_Cable.webp",
		ref: "El-Reeling NSHTÖU 0.6-1KV-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 67,
		nombre_comercial: "PROTECCION MECANICA",
		hover: "CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS",
		imagen: "67_Cable_Plus_(N)SHTOU_(SMK)-BP.webp",
		ref: "Plus (N)SHTÖU (SMK)-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 68,
		nombre_comercial: "CABLES PLANOS",
		hover: "",
		imagen: "68_El-Flat_H07VVH6-F_450-750V_BP_Cable.webp",
		ref: "El-Flat H07VVH6-F 450-750V-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 69,
		nombre_comercial: "CABLES PLANOS",
		hover: "",
		imagen: "69_El-Flat-CY_KYCFLY_300-500V_BP_Cable.webp",
		ref: "El-FlatCY KYCFLY 300-500V-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 70,
		nombre_comercial: "CABLES PLANOS",
		hover: "",
		imagen: "70_El-Flat_NGFLGOU_0-6_1KV-BPCable.webp",
		ref: "El-Flat NGFLGÖU 0.6-1KV-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 71,
		nombre_comercial: "APLICACIÓNES MOVILES",
		hover:
			"CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS /SHD-GC /G-GC",
		imagen: "71_El-MVSt_(N)TMCGEWOU_3-6_6KV-18_30KV-BP_Cable.webp",
		ref: "El-MVSt (N)TMCGEWÖU 3.6-6KV_18-30KV-BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 72,
		nombre_comercial: "APLICACIÓNES MOVILES",
		hover:
			"CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS /SHD-GC /G-GC",
		imagen: "72_El-MVMines_(N)TSCGEWOU_3-6_6KV-12_20KV-BP_Cable.webp",
		ref: "El-MVMines (N)TSCGEWÖU 3.6-6KV_12-20KV_BP Cable",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.PORTUARIO,
		],
	},
	{
		id: 73,
		nombre_comercial: "APLICACIÓNES MOVILES",
		hover:
			"CABLE EXTRAFLEXIBLE / CABLE PATA MINERIA / CABLES PARA GRUAS /SHD-GC /G-GC",
		imagen: "73_Cable_El-MVTunnel_(N)3GHSSHCH_3-6-6KV_12-20_KV.webp",
		ref: "Cable El-MVTunnel (N)3GHSSHCH 3.6-6KV_12-20 KV",
		categorias: [
			CATEGORIAS.MOVILES,
			CATEGORIAS.MINERIA,
			CATEGORIAS.SUMERGIBLE,
			CATEGORIAS.TUNELES,
			CATEGORIAS.FERROVIAS,
			CATEGORIAS.PORTUARIO,
		],
	},
];
