import { Link } from "react-router-dom";

import { ReactComponent as WhatsAppIcon } from "../../assets/images/whatsapp.svg";
import "./hablemos-banner.styles.scss";

const HablemosBanner = () => {
	return (
		<section className="hablemos_container">
			<div className="text_container">
				CONOCE MÁS SOBRE LO QUE PODEMOS HACER POR TI
			</div>
			<Link
				to={"https://wa.me/573166879902"}
				rel="noopener"
				className="button_container"
				target="_blank"
			>
				<div className="whatsapp_icon">
					<WhatsAppIcon />
				</div>
				<div className="contact_button">Hablemos</div>
			</Link>
		</section>
	);
};

export default HablemosBanner;
