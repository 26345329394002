import ContentIcon from "../../components/content-icon/content-icon.component";
import IconTitleBar from "../../components/icon-title-bar/icon-title-bar.component";

import { FaWhatsapp } from "react-icons/fa";

import { getImage } from "../../utils/image-provider";

import "./homologacion.styles.scss";

const HomologacionPage = () => {
	return (
		<div>
			<div className="homologacion-header">
				<div className="title">
					<div className="title_clear">CUENTANOS DE</div>
					<div className="title_dark">TU PROYECTO</div>
					<a href="https://wa.me/573166879902" rel="noreferrer" target="_blank">
						<div className="contacto">
							<div className="whatsapp-container-footer">
								<FaWhatsapp />
							</div>
							<div className="homologacion_hablemos">Hablemos</div>
						</div>
					</a>
				</div>
			</div>
			<div className="homologacion_content">
				<ContentIcon
					image=""
					sectionTitle="Homologación"
					title=""
					hasShadow={true}
					text="Abordamos los desafíos relacionados con cables eléctricos a través de la aplicación o uso de los mismos, colaborando estrechamente con el usuario en áreas como diseño y homologación técnica, suministro en el sitio, soporte postventa con garantía, y ofreciendo una solución integral para la gestión posconsumo de desechos de cable. Esta solución cuenta con Certificación Ambiental y fomenta la Economía Circular."
				/>
			</div>
			<div className="homologacion_banner">
				{getImage("TeamImage", "100%", "Epic Cables - Homologación")}
			</div>
			<div className="homologacion_casos-exito">
				<div className="homologacion_casos-exito__header">
					CASOS DE ÉXITO EPIC
				</div>
				<div className="subestaciones">
					<div className="titleComp">
						<IconTitleBar
							title="Subestaciones"
							theme="dark"
							icon={"SubestacionesDark"}
						/>
					</div>
					<div className="subestaciones__content">
						<div className="contenido">
							<div className="contenido__left">
								<div className="contenido__left__image">
									{getImage(24, "100%", "media tensión")}
								</div>
								<div className="contenido__left__content">
									<p>EiserPro - Cables especiales</p>
									<ul>
										<li>
											Duración: <b>Sep - Dic 2022</b>
										</li>
										<li>
											Valor total (COP): <b>1.486M</b>
										</li>
										<li>
											Tipo de cliente: <b>Generadora Termoeléctrica</b>
										</li>
										<li>
											Usuario final: <b>TermoGuajira</b>
										</li>
									</ul>
								</div>
							</div>
							<div className="contenido__right">
								<p>Cable aislado de media tensión 15kV 2000MCM</p>
								<p>
									<b>Problemática:</b>
									<br /> Un cable de potencia de características particulares se
									encontraba averiado y debía ser remplazado antes de finalizar
									2022 con certificado RETIE vigente.
								</p>
								<p>
									<b>Solución:</b>
									<br /> Se diseñó, homologó y suministró conductor de
									características especiales en menos de 90 días con certificado
									RETIE vigente
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomologacionPage;
