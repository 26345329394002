import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CATEGORIAS } from "../../utils/products.data";
import { getImage } from "../../utils/image-provider";

const Hero = () => {
	const settings = {
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
		dots: false,
	};
	return (
		<Slider {...settings}>
			<div>{getImage(CATEGORIAS.HOME, "100%", "Epic Cables", false)}</div>
			<div>{getImage("heroTrabajo", "100%", "Epic Cables", false)}</div>
			<div>{getImage("Hero2", "100%", "Epic Cables", false)}</div>
			<div>{getImage("Hero3", "100%", "Epic Cables", false)}</div>
			<div>{getImage("Hero4", "100%", "Epic Cables", false)}</div>
			<div>{getImage("Hero5", "100%", "Epic Cables", false)}</div>
			<div>{getImage("Hero6", "100%", "Epic Cables", false)}</div>
			<div>{getImage("Hero7", "100%", "Epic Cables", false)}</div>
		</Slider>
	);
};

export default Hero;
