import ContentIcon from "../../components/content-icon/content-icon.component";
import YellowSpacer from "../../components/yellow-spacer/yellow-spacer.component";
import ContentImage from "../../components/content-image/content-image.component";

import "./economia-circular.styles.scss";

const EconomiaCircular = () => {
	return (
		<div>
			<ContentIcon
				image="Circular_Recompensa"
				sectionTitle="Economía Circular"
				title="Recompra de cables, un beneficio para ti y el planeta."
				text="Gracias a nuestro firme compromiso con el medio ambiente, recompramos a los usuarios los cables desechados o excedentes de obra, lo que contribuye a evitar el crecimiento del mercado negro de productos eléctricos.\nAl vender tus cables usados a EPIC, obtienes un saldo a favor que puedes usar para adquirir productos de nuestra empresa o de nuestros aliados. Esto te permite obtener un beneficio económico por tus materiales reciclados y, al mismo tiempo, te ayuda a mejorar la gestión financiera de tu empresa."
				textAlign="left"
				titleAlign="left"
			/>
			<YellowSpacer topSpacer={false} />
			<ContentImage
				image="EconomiaCircularBig"
				altImage="Reinserción de residuos a la cadena productiva"
				title="Reinserción de residuos a la cadena productiva"
				text="Epic, realiza el proceso de reciclaje de los cables de uso eléctrico, reinsertando los materiales que los componen como materias primas para la industria. Como valor agregado a este proceso, Epic, entrega evidencia de la destrucción y certifica, por medio de licencia ambiental aprobada por el ministerio de medio ambiente, la correcta disposición de los cables recomprados como desperdicio."
				imagePosition="left"
				/>
			<YellowSpacer topSpacer={true} />
			<ContentImage
				image="Procesos"
				altImage="Certificación de destrucción del material para disposición final"
				title="Certificación de destrucción del material para disposición final"
				text="Con el acompañamiento del personal encargado realizaremos el proceso de corte y destrucción del producto certificando el aprovechamiento de las materias primas y que este no será utilizado para fines eléctricos."
				imagePosition="right"
			/>
		</div>
	);
};

export default EconomiaCircular;
