import IconTitleBar from "../icon-title-bar/icon-title-bar.component";

import { getImage } from "../../utils/image-provider";
import "./content-icon.styles.scss";

const ContentIcon = ({
	sectionTitle,
	image,
	altImage,
	title,
	text,
	hasShadow,
	textAlign = 'center',
	titleAlign = 'center',
}) => {
	return (
		<div className="content-icon">
			<div
				className={`content-icon__left-content ${hasShadow && "extra-width"}`}
			>
				<IconTitleBar title={sectionTitle} />
				<div className='content-icon__left-content__content-text' style={{textAlign: titleAlign}}>
					{title !== "" && <h2>{title}</h2>}
					<p className={hasShadow && "has-shadow"} style={{textAlign: textAlign }}>{text}</p>
				</div>
			</div>
			<div className="content-icon__right-content">
				{image !== "" && (
					<div className="content-icon__content-img">
						{getImage(image, "100%", altImage)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ContentIcon;
