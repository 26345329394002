import { useState } from "react";
import { Link } from "react-router-dom";

import IconTitleBar from "../../components/icon-title-bar/icon-title-bar.component";

import { PRODUCTS, CATEGORIAS } from "../../utils/products.data";
import { getImage } from "../../utils/image-provider";

import { FaWhatsapp } from "react-icons/fa";
import "./productos.styles.scss";

const ProductosPage = () => {
	const [products, setProducts] = useState(PRODUCTS);
	const categories = Object.values(CATEGORIAS);

	const clickHandler = (category) => {
		const newFilteredProducts = PRODUCTS.filter((product) => {
			return product.categorias.includes(category);
		});

		setProducts(newFilteredProducts);
	};

	const changeHandler = (event) => {
		clickHandler(event.target.value);
	};

	return (
		<>
			<div className="productosHeader">
				<div>
					<IconTitleBar title="Productos" theme="clear" />
					<div className="productosHeader_content">
						Nuestro compromiso es asegurar tu elección ideal en cables,
						considerando detalladamente especificaciones, precio, calidad y
						disponibilidad, comunícate directamente con nuestros Ingenieros de
						Servicio a través de nuestro &nbsp;
						<a href="https://wa.me/573166879902?text=Hola!%20Estoy%20buscando%20una%20referencia%20que%20no%20está%20en%20la%20página">
							WhatsApp aquí
						</a>
						&nbsp; o da click sobre la referencia deseada para poder brindarte
						toda la información necesaria.
					</div>
					<div className="wp-container">
						<a
							href="https://wa.me/573166879902"
							rel="noreferrer"
							target="_blank"
							className="footer-whatsapp"
						>
							<span>
								<FaWhatsapp />
							</span>
						</a>
					</div>
				</div>
				<div>
					<br />
					<br />
					{getImage("productosDescripcion", "100%", "Productos")}
				</div>
			</div>
			<div className="productos_title">
				<p>SU ELECCIÓN IDEAL DE CABLES</p>
			</div>
			<br />
			<br />
			<div className="productos-container">
				<div className="categorias_container_mobile">
					<select name="categoriar" onChange={changeHandler}>
						<option value={null}>Selecciona una categoría</option>
						{categories.map((cat, idx) => (
							<option key={idx} value={cat}>
								{cat}
							</option>
						))}
					</select>
				</div>
				<div className="productos-content">
					<div className="categories_container">
						{categories.map((cat, idx) => {
							const icon = getImage(cat, 80, cat);
							return (
								<div
									key={idx}
									className="category"
									onClick={() => clickHandler(cat)}
								>
									{icon}
								</div>
							);
						})}
					</div>
					<div className="products_list">
						{products.map(({ id, nombre_comercial, ref }) => (
							<Link
								className="product"
								key={id}
								to={`https://wa.me/573166879902?text=Hola!%20estoy%20interesado%20en%20la%20siguiente%20referencia:%20${ref.replace(
									" ",
									"%20"
								)}`}
								target="_blank"
								title={ref}
							>
								<div className="product_image">{getImage(id, 50, ref)}</div>
								<div className="nombre_container">
									<p className="nombre_producto">{nombre_comercial}</p>
								</div>
							</Link>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductosPage;
