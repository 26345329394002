import { getImage } from "../../utils/image-provider";
import "./icon-title-bar.styles.scss";

const IconTitleBar = ({ title, theme = "clear", icon = "RayoIcon" }) => {
	return (
		<div className="icon-title-bar-container">
			<div className={`icon-title-bar-container__title ${theme}`}>
				<span>{`${title}`}</span>
			</div>
			<div className={`icon-title-bar-container__icon ${theme}`}>
				{getImage(icon, 70, icon)}
			</div>
		</div>
	);
};

export default IconTitleBar;
