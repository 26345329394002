import { getImage } from "../../utils/image-provider";

import "./content-image.styles.scss";

const ContentImage = ({
	image,
	altImage,
	title,
	text,
	imagePosition = "left",
}) => {
	if (imagePosition === "left") {
		return (
			<div className="contentimage-container">
				<div className="contentimage-container__image-container">
					{getImage(image, "100%", altImage)}
				</div>
				<div className="contentimage-container__text-content">
					<div className="contentimage-container__text-content__text-container">
						<h2>{title}</h2>
						<p>{text}</p>
					</div>
				</div>
			</div>
		);
	} else if (imagePosition === "right") {
		return (
			<div className="contentimage-container">
				<div className="contentimage-container__text-content">
					<div
						className="contentimage-container__text-content__text-container"
						style={{ textAlign: "left" }}
					>
						<h2>{title}</h2>
						<p>{text}</p>
					</div>
				</div>
				<div className="contentimage-container__image-container">
					{getImage(image, "100%", altImage)}
				</div>
			</div>
		);
	}
};

export default ContentImage;
