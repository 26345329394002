import { getImage } from "../../utils/image-provider";

import casosData from "../../utils/casos.data.json";

import "./casos-section.styles.scss";

const CasosSection = () => {
	return (
		<section className="casos_exito">
			<h2>Casos de éxito</h2>
			<div className="casos_container">
				{casosData.map(({ empresa, image, mensaje }, idx) => (
					<div className="casos_container__casos_item" key={idx}>
						{getImage(image, 150, empresa, true)}
						<h3>{empresa}</h3>
						<div>{mensaje}</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default CasosSection;
