import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { initGA } from "./utils/analytics";
import usePageTracking from "./utils/usePageTraking";
import Navigate from "./pages/navigate/navigate";
import HomePage from "./pages/home/home.page";
import ProductosPage from "./pages/productos/productos.page";
import EconomiaCircular from "./pages/economia-circular/economia-circular.page";
import HomologacionPage from "./pages/homologacion/homologacion.page";
import NosotrosPage from "./pages/nosotros/nosotrosPage";

function App() {
	useEffect(() => {
		initGA();
	}, []);

	usePageTracking();
	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate />}>
					<Route index element={<HomePage />} />
					<Route path="nosotros" element={<NosotrosPage />} />
					<Route path="economia-circular" element={<EconomiaCircular />} />
					<Route path="homologacion" element={<HomologacionPage />} />
					<Route path="productos" element={<ProductosPage />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
