import ConectionBanner from "../../components/conection-banner/conection-banner.component";
import HablemosBanner from "../../components/hablemos-banner/hablemos-banner.component";
import ServiciosSection from "../../components/servicios-section/servicios-section.component";
import CasosSection from "../../components/casos-section/casos-section.component";

import "./home.styles.scss";

const HomePage = () => {
	return (
		<>
			<div className="home-content">
				<ConectionBanner />
				<HablemosBanner />
				<ServiciosSection />
				<CasosSection />
			</div>
		</>
	);
};

export default HomePage;
