import { Link } from "react-router-dom";

import { getImage } from "../../utils/image-provider";

import "./conection-banner.styles.scss";

const ConectionBanner = () => {
	return (
		<section className="conexion_container">
			<div className="text_container">
				TU CONEXIÓN <br /> <span className="marked_text">PRECISA</span> Y <br />
				SOSTENIBLE
			</div>
			<div className="conexion_container__consultoria">
				<div className="consultoria__texto">
					<Link to={"/homologacion"}>
						Consultoría/
						<br />
						Homologación
					</Link>
				</div>
				<div className="conexion_container__consultoria__imagen">
					{getImage("DiagramaCircular", "100%", "Diagrama Circular")}
				</div>
				<div className="conexion_container__consultoria__bloque">
					<div className="consultoria__texto">
						<Link to={'/economia-circular'}>Sostenibilidad /<br/>Economía Circular</Link>
					</div>
					<div className="consultoria__texto">
						<Link to={'/productos'}>Suministro de<br/>Productos</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ConectionBanner;
