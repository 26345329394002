import "./slide-indicator.styles.scss";

const SlideIndicator = ({ currentPosition, totalItems }) => {
	return (
		<div className="sub_title-pagination">
			<span>
				{currentPosition + 1} / {totalItems}
			</span>
		</div>
	);
};

export default SlideIndicator;
